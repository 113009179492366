/* General container styles */
.squestions-container {
  padding: 10px;
  width: 100%;
  /* height: 100%; */
  box-sizing: border-box;
  background-color: white;
  border-radius: 20px;
  margin-bottom: 20px;
}

/* Title styles */
.squestions-title {
  color: #000;
  text-align: center;
  margin-bottom: 22px;
  font-size: 2.5rem; /* Increased for emphasis */
  font-weight: bold;
}

.squestions-title h1 {
  font-family: inherit;
  font-size: 2.5rem; /* Consistent with .squestions-title */
}

/* Survey Question Number */
.survey-question-number {
  font-size: 1.8rem;
  font-weight: bold;
  text-align: center;
  font-family: inherit;
  color: white;
  
}

/* Audio sections */
.survey-audios {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 20px;
}

.audio-column {
  width: 45%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
  color: #000;
}

.audio-column h3, 
.survey-question-number span, 
.squestions-title h1 {
  font-family: inherit;
  font-size: 1.8rem; /* Increased for consistency */
}

.audio-wrapper {
  margin-bottom: 10px;
}

/* Test audio section */
.test-audio-section {
  margin-top: 30px;
  text-align: center;
  font-size: 1.6rem; /* Increased readability */
}

/* Button section */
.button-section {
  margin-top: 20px;
  margin-bottom: 20px;
  text-align: center;
  display: flex;
  justify-content: center;
  gap: 10px;
}

/* Option button styles */
.option-button {
  padding: 15px 30px;
  width: 280px;
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 1.6rem; /* Increased font size for better readability */
  font-weight: bold;
  border-radius: 8px;
  cursor: pointer;
  border: 1px solid #ccc;
  transition: border 0.2s, transform 0.2s;


    /* width: 100%; */
    /* padding: 8px 16px; */
    /* border-radius: 5px; */
    /* cursor: pointer; */
    /* transition: 0.3s; */
    /* font-size: 1.5rem; */
    /* margin-top: 20px;
    margin-bottom: 20px; */
    font-weight: bold;
    font-family: "Arial", sans-serif; /* 固定字体 */
}

.option-button.option-a {
  background-color: #a099d4;
  color: #fff;
}

.option-button.option-b {
  background-color: #f996ad;
  color: #fff;
}

.option-button.option-c {
  background-color: #f0f3f4;
  color: #000;
}

.option-button.selected {
  border: 3px solid #000;
  transform: scale(1.05);
}

/* Selected option display */
.selected-option {
  margin-top: 20px;
  text-align: center;
  font-weight: bold;
  font-size: 1.8rem; /* Ensuring visibility */
}

/* Responsive adjustments */
@media screen and (max-width: 768px) {
  .survey-audios {
    flex-direction: column;
  }

  .audio-column {
    width: 100%;
  }

  .option-button {
    width: 90%;
    max-width: 400px;
    height: auto;
    padding: 15px 20px;
    font-size: 1.4rem; /* Adjust for smaller screens */
  }

  .test-audio-section {
    margin-top: 20px;
    font-size: 1.4rem; /* Maintain readability */
  }

  .squestions-container {
    padding: 10px;
  }

  .button-section {
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
  }

  .squestions-title {
    font-size: 2rem;
  }

  .survey-question-number {
    font-size: 1.6rem;
  }
}

@media screen and (max-width: 400px) {
  .squestions-title {
    font-size: 1.8rem;
  }

  .survey-question-number {
    font-size: 1.4rem;
  }

  .option-button {
    font-size: 1.5rem;
    font-weight: bold;
    font-family: "Arial", sans-serif;
  }

  .test-audio-section {
    font-size: 1.2rem;
  }
}
