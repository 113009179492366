/* Global font settings */
/* * {
  font-family: sans-serif;
} */

/* General container styles */





  .survey-container {
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    /* margin: 30px; */
    padding: 20px;
    height: 100vh;
    /* background: linear-gradient(135deg, #ff9a9e, #fad0c4); */
    background: linear-gradient(to right, #5ca9fb 0%, #6372ff 100%);
    /* border-radius: 10px; */
    /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); */
    font-family: "Arial", sans-serif; /* 固定字体 */
  }
  
  .survey-title {
    text-align: center;
    font-size: 4rem;
    color: white;
    font-weight: bold;
    margin-bottom: 2px;
    font-family: "Arial", sans-serif; /* 固定字体 */
  }
  
  .survey-subtitle {
    font-size: 1.5rem;
    padding: 8px 2px;
    color: white;
    font-family: "Arial", sans-serif; /* 固定字体 */
  }
  
  .survey-start {
    background: white;
    font-size: 1.2rem;
    padding: 2px 16px;
    border-radius: 10px;
    width: 100%;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    font-family: "Arial", sans-serif; /* 固定字体 */
    /* text-align: center; */
  }

  .survey-start h3 {
    font-size: 1.5rem;
    font-family: inherit;
    font-family: "Arial", sans-serif; /* 固定字体 */
  }
  
  .survey-input, select {
    padding: 8px;
    width: 100%;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin-top: 10px;
    font-family: "Arial", sans-serif; /* 固定字体 */
  }
  
  .survey-start-button {
    background: #5ca9fb;
    color: white;
    width: 100%;
    padding: 8px 16px;
    border-radius: 5px;
    cursor: pointer;
    transition: 0.3s;
    font-size: 1.5rem;
    margin-top: 20px;
    margin-bottom: 20px;
    font-weight: bold;
    font-family: "Arial", sans-serif; /* 固定字体 */
  }
  
  .survey-start-button:hover {
    background: #6372ff;
    font-family: "Arial", sans-serif; /* 固定字体 */
  }

  .survey-navigation {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-bottom: 20px;
    font-family: "Arial", sans-serif; /* 固定字体 */
  }
  
  .survey-previous-button {
    display: flex;
    align-items: center;
    background: none;
    border: none;
    cursor: pointer;
    font-size: 1.4rem;
    font-family: inherit;
    color: white;
    font-weight: bold;
    font-family: "Arial", sans-serif; /* 固定字体 */
  }

  .survey-restart-button {
    background: #5ca9fb;
    color: white;
    width: 100%;
    padding: 12px 20px;
    border-radius: 5px;
    cursor: pointer;
    transition: 0.3s;
    font-size: 1.5rem;
    margin-top: 20px;
    margin-bottom: 20px;
    font-weight: bold;
    font-family: "Arial", sans-serif; /* 固定字体 */
  }
  

  .survey-results-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    padding: 20px;
    background: linear-gradient(135deg, #f9f9f9, #e3e3e3);
    font-family: "Arial", sans-serif; /* 固定字体 */
  }
  
  .survey-results-card {
    margin-top: 10px;
    background: white;
    padding: 30px 20px;
    border-radius: 12px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
    font-family: "Arial", sans-serif; /* 固定字体 */
    /* max-width: 500px; */
    width: 100%;
    margin-bottom: 5px;
  }
  
  .survey-results-title {
    font-size: 1.8rem;
    color: #333;
    font-weight: bold;
    margin-bottom: 10px;
    font-family: "Arial", sans-serif; /* 固定字体 */
  }
  
  .survey-thank-you {
    font-size: 1.2rem;
    color: #666;
    margin-bottom: 20px;
    font-family: "Arial", sans-serif; /* 固定字体 */
  }
  
  .survey-score {
    background: #f4f4f4;
    padding: 10px;
    font-family: "Arial", sans-serif; /* 固定字体 */
    /* border-radius: 8px; */
    box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .score-text {
    font-size: 1.2rem;
    color: #444;
    margin: 3px 0;
    font-family: "Arial", sans-serif; /* 固定字体 */
  }
  
  .score-number {
    font-size: 2.5rem;
    font-weight: bold;
    color: #4CAF50;
    font-family: "Arial", sans-serif; /* 固定字体 */
  }

  .survey-results {
    width: 100%;
    font-size: 1.2rem;
    font-family: "Arial", sans-serif; /* 固定字体 */
  }

  .result-details.expanded {
    font-size: 1.2rem;
    background-color: white;
    border: 3px solid #f4f4f4;
    padding: 10px;
    text-align: left;
    font-weight: bold;
    font-family: "Arial", sans-serif; /* 固定字体 */
  }

@media screen and (min-height: 700px) {

  .survey-restart-button {
    margin-top: 10px;
    font-family: "Arial", sans-serif; /* 固定字体 */
  }
 
}
  